import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  },
  {
    path: '/Login',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  },
  {
    path: '/Admin',
    component: () => import(/* webpackChunkName: "about" */ '../views/Center.vue'),
    children: [
      {
        path: 'CheckInHistory',
        name: 'Check In History',
        component: () => import('../views/Center/Home')
      },
      {
        path: 'CheckInCode',
        name: 'Check In Code',
        component: () => import('../views/Center/CheckInCode')
      },
      {
        path: 'News',
        name: "News",
        component: () => import('../views/Center/News')
      },
      {
        path: 'FAQ',
        name: "FAQ",
        component: () => import('../views/Center/FAQ')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
