<template>
  <div>
    <router-view/>
  </div>
</template>

<style>
</style>

<script>
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useRouter } from 'vue-router'
import { message } from 'ant-design-vue';


export default {
  created() {
    const router = useRouter()
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        console.log(uid)
      } else {
        message.error('You haven\'t sign in yet!');
        router.push({path: "/Login"})
      }
    });
  }
}
</script>