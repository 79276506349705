import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

import firebase from 'firebase/compat/app';
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'


const firebaseConfig = {
    apiKey: "AIzaSyB-NGuU5R6gaFd_ua9RpUnYX_2d7COlIWo",
    authDomain: "ihealth-8ab49.firebaseapp.com",
    projectId: "ihealth-8ab49",
    storageBucket: "ihealth-8ab49.appspot.com",
    messagingSenderId: "957435531409",
    appId: "1:957435531409:web:515d08ce2c7cbbd7a1ef30",
    measurementId: "G-HMZETHT9YK"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);


createApp(App)
    .use(store)
    .use(Antd)
    .use(router)
    .mount('#app')
